<template>
  <div class="contact-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-12 col-md-12">
          <div class="contact-info">
            <h3>Contact Address:</h3>
            <p>
              Contact us with your details & ready to start with us. Get In
              Touch!
            </p>
            <ul class="contact-list">
              <li>
                <div class="icon">
                  <i class="bx bx-support"></i>
                </div>
                <p><a href="tel:+3058747784">+1 (216) 243-0012</a></p>
              </li>
              <li>
                <div class="icon">
                  <i class="bx bx-globe"></i>
                </div>
                <p>
                  <a href="mailto:info@Terracore-inv.com"
                    >info@Terracore-inv.com</a
                  >
                </p>
              </li>
              <li>
                <div class="icon">
                  <i class="bx bx-map"></i>
                </div>
                <p>1055 West 7th Street, 33rd Floor, Los Angeles, CA 90017</p>
              </li>
            </ul>
            <ul class="social-links">
              <li>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  class="facebook"
                >
                  <i class="bx bxl-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                  class="twitter"
                >
                  <i class="bx bxl-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  class="linkedin"
                >
                  <i class="bx bxl-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  class="instagram"
                >
                  <i class="bx bxl-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12">
          <div class="maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.728422723306!2d-118.26522068478492!3d34.05083688060622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c7b067b00001%3A0x109c4ca414a0489!2sARCO%20Tower%2C%201055%20W%207th%20St%2033rd%20Floor%20(Penthouse%2C%20Los%20Angeles%2C%20CA%2090017!5e0!3m2!1sen!2sus!4v1663455168074!5m2!1sen!2sus"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >
            </iframe>
          </div>
        </div>
      </div>
      <div class="contact-form">
        <div class="section-title">
          <h2>Get In Touch!</h2>
          <p>Our Terracore support community can help you find answers.</p>
        </div>
        <form
          id="contactForm"
          action="https://formsubmit.co/info@Terracore-inv.com"
          method="post"
        >
          <input type="hidden" name="_captcha" value="false" />
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  required
                  data-error="Please enter your name"
                  placeholder="Eg: Sarah Taylor"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  id="email"
                  required
                  data-error="Please enter your email"
                  placeholder="hello@sarah.com"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  name="phone_number"
                  class="form-control"
                  id="phone_number"
                  required
                  data-error="Please enter your phone number"
                  placeholder="Enter your phone number"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  name="msg_subject"
                  class="form-control"
                  id="msg_subject"
                  placeholder="Enter your subject"
                  required
                  data-error="Please enter your subject"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <textarea
                  name="message"
                  id="message"
                  class="form-control"
                  cols="30"
                  rows="6"
                  required
                  data-error="Please enter your message"
                  placeholder="Enter message..."
                ></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button type="submit" class="default-btn">
                <i class="bx bx-paper-plane"></i> Send Message
              </button>
              <div id="msgSubmit" class="h3 text-center hidden"></div>
              <div class="clearfix"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>