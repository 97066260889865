<template>
  <div class="cryptocurrency-area ptb-100 bg-fffbf1">
    <div class="container">
      <div class="section-title">
        <h2>Top Cryptocurrencies</h2>
        <p>
          Take advantage of a highly liquid market with round-the-clock trading.
          Profit from correctly predicting the movement of world's most popular
          cryptocurrencies.
        </p>
      </div>
      <div class="cryptocurrency-table table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Projected Price</th>
              <th scope="col">Change (24h)</th>
              <th scope="col">Trade</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/img/cryptocurrency/bitcoin.png"
                    alt="image"
                  />
                  <h3 class="mb-0">BITCOIN</h3>
                </div>
              </td>
              <td>USD 55102.7</td>
              <td><span class="trending up">+1.54%</span></td>
              <td>
                <router-link to="/prices" class="link-btn">BUY</router-link>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/img/cryptocurrency/ethereum.png"
                    alt="image"
                  />
                  <h3 class="mb-0">ETHEREUM</h3>
                </div>
              </td>
              <td>USD 45214.3</td>
              <td><span class="trending up">+1.20%</span></td>
              <td>
                <router-link to="/prices" class="link-btn">BUY</router-link>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/img/cryptocurrency/lightcoin.png"
                    alt="image"
                  />
                  <h3 class="mb-0">LITECOIN</h3>
                </div>
              </td>
              <td>USD 874.7</td>
              <td><span class="trending down">-2.49%</span></td>
              <td>
                <router-link to="/prices" class="link-btn">BUY</router-link>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/img/cryptocurrency/cardano.png"
                    alt="image"
                  />
                  <h3 class="mb-0">CARDANO</h3>
                </div>
              </td>
              <td>USD 54124.8</td>
              <td><span class="trending up">+1.07%</span></td>
              <td>
                <router-link to="/prices" class="link-btn">BUY</router-link>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/img/cryptocurrency/xrp.png"
                    alt="image"
                  />
                  <h3 class="mb-0">XRP</h3>
                </div>
              </td>
              <td>USD 45241.7</td>
              <td><span class="trending down">-1.20%</span></td>
              <td>
                <router-link to="/prices" class="link-btn">BUY</router-link>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/img/cryptocurrency/stellar.png"
                    alt="image"
                  />
                  <h3 class="mb-0">STELLAR</h3>
                </div>
              </td>
              <td>USD 14123.5</td>
              <td><span class="trending up">+0.54%</span></td>
              <td>
                <router-link to="/prices" class="link-btn">BUY</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="more-cryptocurrency-box text-center">
        <router-link to="/prices" class="default-btn">
          <i class="bx bx-purchase-tag-alt"></i>
          See Live Prices
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopCryptocurrencies",
};
</script>