<template>
  <accordion>
    <accordion-item>
      <template v-slot:accordion-trigger>
        <button class="accordion-button">What is forex?</button>
      </template>
      <template v-slot:accordion-content>
        <p>
          Foreign exchange, or forex, is the global market of the world’s
          currencies, where the values of different currencies are pitted
          against each other in the form of forex pairs, such as EUR/USD,
          AUD/JPY, etc. The forex market determines the exchange rates of each
          currency.
        </p>
      </template>
    </accordion-item>

    <accordion-item>
      <template v-slot:accordion-trigger>
        <button class="accordion-button" type="button">
          Is Terracore regulated?
        </button>
      </template>
      <template v-slot:accordion-content
        ><p>Yes, we’re regulated by:</p>
        <ol>
          <li>the Malta Financial Services Authority</li>
          <li>the Labuan Financial Services Authority</li>
          <li>the Vanuatu Financial Services Commission</li>
          <li>the British Virgin Islands Financial Services Commission</li>
        </ol></template
      >
    </accordion-item>

    <accordion-item>
      <template v-slot:accordion-trigger>
        <button class="accordion-button" type="button">
          How can I automate my trading strategy?
        </button>
      </template>
      <template v-slot:accordion-content>
        You can automate your trading strategy using a trading bot. A trading
        bot is an automated computer program that purchases trade contracts for
        you while following a specific set of instructions that you provide.
      </template>
    </accordion-item>

    <accordion-item>
      <template v-slot:accordion-trigger>
        <button class="accordion-button" type="button">
          What assets are available for trading on weekends?
        </button>
      </template>
      <template v-slot:accordion-content>
        <p>
          Synthetic indices and cryptocurrencies are available for trading 24/7.
        </p></template
      >
    </accordion-item>

    <accordion-item>
      <template v-slot:accordion-trigger>
        <button class="accordion-button" type="button">
          How long does it take for my deposits and withdrawals to be processed?
        </button>
      </template>
      <template v-slot:accordion-content>
        <p>
          We process your deposits and withdrawals internally within 1 to 3
          business days depending on your payment method. It may take longer for
          your funds to reach you due to the different processing times by banks
          and payment service providers.
        </p>
      </template>
    </accordion-item>

    <accordion-item>
      <template v-slot:accordion-trigger>
        <button class="accordion-button" type="button">
          What conversion rates do you use for deposits and withdrawals?
        </button>
      </template>
      <template v-slot:accordion-content>
        <p>
          The currency conversion for your deposits and withdrawals are done by
          your payment service provider. For example, if you use Skrill, your
          transaction amount will be converted by Skrill. Please check with your
          service provider regarding the conversion rates used for your deposits
          and withdrawals.
        </p>
      </template>
    </accordion-item>
  </accordion>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
  name: "AccordionContant",
  components: {
    Accordion,
    AccordionItem,
  },
};
</script>