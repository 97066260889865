<template>
  <div class="buy-sell-cryptocurrency-area pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Features</h2>
        <p>A Powerful platform With Advanced Features yet Easy to trade</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-buy-sell-item">
            <div class="icon">
              <img src="../../assets/img/icon/icon12.png" alt="image" />
            </div>
            <h3>Top Security</h3>
            <p>
              All Data is completly encrypted putting you in the driving sit to
              ensure you & your funds are safe.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-buy-sell-item">
            <div class="icon">
              <img src="../../assets/img/icon/icon13.png" alt="image" />
            </div>
            <h3>Crypto Wallets</h3>
            <p>
              Get Bitcoin & other Alt-coins deliverd directly to your soft or
              hard wallet.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-buy-sell-item">
            <div class="icon">
              <img src="../../assets/img/icon/icon14.png" alt="image" />
            </div>
            <h3>Secure Payment</h3>
            <p>
              Withdrawals & deposits are completly secured, making all payment
              secured.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-buy-sell-item">
            <div class="icon">
              <img src="../../assets/img/icon/icon15.png" alt="image" />
            </div>
            <h3>Insured</h3>
            <p>
              All funds are insured to prevent loss, making us the first company
              in the world to achieve this.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-buy-sell-item">
            <div class="icon">
              <img src="../../assets/img/icon/icon16.png" alt="image" />
            </div>
            <h3>Universal Access</h3>
            <p>
              Enjoy access from anwhere in the world as we are currently
              registed in over 23 Countries.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-buy-sell-item">
            <div class="icon">
              <img src="../../assets/img/icon/icon17.png" alt="image" />
            </div>
            <h3>Get Bonus</h3>
            <p>
              Get a bonus on sign up, first deposit & on all transaction & when
              you refer us.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="shape13">
      <img src="../../assets/img/shape/shape13.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BuyAndSell",
};
</script>