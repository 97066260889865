<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Learn Everything About Bitcoin!" />
        <Bitcoin />
        <SelectCryptocurrencies />
        <StartTrading />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Bitcoin from '../CryptocurrencyDetails/Bitcoin'
import SelectCryptocurrencies from '../CryptocurrencyDetails/SelectCryptocurrencies'
import StartTrading from '../CryptocurrencyDetails/StartTrading'
import Footer from '../Layouts/Footer'

export default {
    name: 'CryptocurrencyDetailsPage',
    components: {
        Navbar,
        PageTitle,
        Bitcoin,
        SelectCryptocurrencies,
        StartTrading,
        Footer,
    }
}
</script>