<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Terms & Conditions" />
    <TermsConditions />
    <StartTrading heading="Start Trading on Terracore" />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import TermsConditions from "../TermsConditions/TermsConditions";
import StartTrading from "../Common/StartTrading";
import Footer from "../Layouts/Footer";

export default {
  name: "TermsConditionsPage",
  components: {
    Navbar,
    PageTitle,
    TermsConditions,
    StartTrading,
    Footer,
  },
};
</script>