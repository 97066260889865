<template>
  <div class="terms-conditions-area ptb-100">
    <div class="container">
      <div class="terms-conditions-content">
        <h3>Overview</h3>
        <p>
          We may collect personal identification information from Users in a
          variety of ways, including, but not limited to, when Users visit our
          site, subscribe to the newsletter, fill out a form, and in connection
          with other activities, services, features or resources we make
          <a href="#">available</a> on our Site. Users may be asked for, as
          appropriate, name, email address, mailing address, phone number,
          company name. We will collect personal
          <strong>identification</strong> information from Users only if they
          voluntarily consent such information to us. Users can always refuse to
          supply personally identification information, except that it may
          prevent them from engaging in certain Site related activities.
        </p>

        <h3>Applicability</h3>

        These Terms and Conditions (also "Agreement") shall govern the
        commercial relationship between you and us. Where used in this
        Agreement, references to:
        <br />
        <strong>a.</strong> "we", "our", "us" means Relic Investment and, as
        applicable, any of its directors, officers, shareholders, employees,
        advisors, contractors, subsidiaries and any of its affiliated corporate
        entities, providing certain Services (as defined below) and an access to
        the Applications and Data (as defined below) through the Website (as
        defined below) and
        <br />
        <strong>b.</strong> "you and/or "your" mean the individual or entity who
        wishes to use our Services, Applications and Data.<br />
        <br />
        By checking the box and clicking I have read and accepted the Terms and
        Conditions button, or by continuing to use any of our Services, you
        <br /><br />
        <strong>a.</strong>confirm that you are aware and comply with the
        present Agreement and agree to be bound by this Agreement and
        <br />
        <strong>b.</strong> represent and warrant that you are authorized and
        lawfully able to enter into this Agreement. Additional terms or service
        conditions may apply and be shown separately. These additional terms
        become part of your Agreement with us and all the policies made
        available to you therein must be followed. If you do not agree to all
        the terms and conditions of this Agreement, then you may not use any of
        our Services Applications and/or Data. We reserve the right, at our sole
        discretion, to change, modify, add or remove portions of any part of
        these terms and conditions, at any time. We will notify you about the
        changes to the terms and conditions either by e-mail, your Account (if
        any) or through the Website. Your continued use of our Services,
        Website, Applications or Data means that you accept and agree to the
        changes.

        <h3>Definitions and interpretations</h3>

        References to the following words shall have the meanings set out below:
        Account: an account which is created through the Website and used to
        access our Services, Applications and Data. Agreement: any standard
        terms imposed by us to regulate the commercial relationship between you
        and us, including these terms and conditions. Applications: a PC and
        mobile software application developed and owned by us that interacts
        with the Exchanges (including receives information about your
        transactions on such Exchanges), API Keys of which you have entered to
        the Application(s). Client Data: any data obtained by us via the
        Applications about your transactions on the Exchanges (including, but
        not limited to, your account balance, trading activity, and account
        history, if applicable) and delivered to you through the Services. Data:
        includes Public Data, Client Data and Processed Data. Processed Data:
        any Public Data analyzed and compared by us and delivered to you through
        the Services in the processed form. The Processed Data is owned by us.
        Public Data: any market data, prices, information on transactions and
        other data obtained by us from public sources, including exchanges, and
        other sources and delivered to you through the Services. Fees: fees
        payable by you to us for the use of the Website, Services, Applications
        and Data in the amount and under the terms as indicated in the
        Subscription Plan selected by you. Services: Trading Investment and
        analytics, forex and cryptocurrencies "signal" analytics, market
        surveys, cryptocurrency trading, holding cryptocurrency portfolio and
        other services provided by us to you via the Website under the terms of
        this Agreement.
        <br />
        <p>
          References to the word include or including (or any similar term) are
          not to be construed as implying any limitation and general words
          introduced by the word other (or any similar term) shall not be given
          a restrictive meaning because they are preceded or followed by words
          indicating a particular class of acts, matters or things.
        </p>
        <br />
        <h3>Registration</h3>
        <br />
        <p>
          In order to access the Services, you must first register an Account on
          the Website and be in compliance with present Agreement. In order to
          start profiting, you need to create your unique trade account and
          deposit funds.
        </p>
        <br />
        <p>
          Any natural person with full active legal capacity or any legal person
          may apply for an Account. You must provide your real name and full
          personal and/or company registration information. All information you
          provide upon registration must be accurate, complete and up-to-date.
          You may not provide any false information, or create an Account for
          anyone other than yourself without respective authorization. The
          representative of a legal person making the Account on behalf of the
          legal person must ensure that he has all the necessary rights and
          powers to do that. In order to verify your Account, we may request
          additional verification information, such as a copy / scan of your
          passport, ID card, driver s license, utilities bills, payment method
          details.
        </p>
        <br />
        <p>
          We reserve the right to accept or reject your Account registration
          request without additional explanation. Registering an Account implies
          full and total acceptance of all our policies, including these terms
          and conditions.
        </p>
        <br />
        <p>
          You are entirely responsible for maintaining the confidentiality of
          the information you hold for your Account, including your password,
          and for any and all activity that occurs under your Account as a
          result of you failing to keep this information secure and
          confidential. You have to notify us immediately, in case of any
          unauthorized use of your Account.
        </p>
        <br />
        <p>
          In case we find at our sole discretion that there is a threat that you
          are engaging in any suspicious activity, you have provided inaccurate
          or incomplete information during the registration process or
          thereafter, you have violated the Agreement or it is necessary for
          security reasons, we may temporarily suspend your Account until the
          cause for suspending your Account has been eliminated; or if that
          cannot be eliminated or you refuse or fail to eliminate it, terminate
          the Agreement and block your Account. For security reasons we may in
          addition block your access to the entire Website. You agree that we
          will not be liable to you or to any third party for termination of
          your access to your Account and/or the Website as a result of any
          violation of the Agreement by you.
        </p>
        <br />
        <h3>Fee and payment terms</h3>

        <br />

        <p>
          You will pay us the Fees prior to each investment term as specified in
          your investment Plan via the means of payment available on the
          Website. Fees are non-refundable. You hereby irrevocably waive your
          right to a refund on Fees paid to us both during termination, and
          after expiration of the Agreement, unless otherwise is provided in the
          Agreement.
        </p>
        <br />
        <p>
          We may provide updates and/or additions to the Services ( Additions )
          as they become available. You hereby acknowledge that additional Fees
          may be charged for the use of the Additions, as determined by us, if
          you decide to use such Additions. The Fees for Additions shall be made
          available on the Website.
        </p>
        <br />
        <p>
          Fees may include value added tax ( VAT ). You shall provide us with
          any information we reasonably request to determine whether we are
          obliged to collect VAT from you, including your VAT identification
          number.
        </p>
        <br />
        <p>
          If according to applicable law it is your responsibility to declare,
          pay or withhold taxes on or from your transactions with us, you agree
          to indemnify and hold us harmless from and against any claim arising
          out of your failure to do so.
        </p>
        <br />
        <h3>Use of website,services, applications and data</h3>
        <br />
        <p>
          All site, product and services content as text, graphics, user
          interfaces, visual interfaces, photographs, trademarks, logos, artwork
          and computer code, including but not limited to the design, structure,
          selection, coordination, expression and arrangement of such content,
          contained on the Website is owned, controlled or licensed by us, and
          is protected by copyright and trademark laws, and various other
          intellectual property rights and unfair competition laws. Applications
          are owned, controlled or licensed by us, and are protected by
          copyright and trademark laws, and various other intellectual property
          rights and unfair competition laws.
        </p>
        <br />
        <p>
          No part and no content may be copied, reproduced, republished, posted,
          publicly displayed, encoded, translated, transmitted or distributed in
          any way for publication or distribution of for any commercial
          enterprise, without our express written consent.
        </p>
        <br />
        <p>
          You may use information about our Services purposely made available by
          us for downloading from the Website, provided that you use such
          information only for your personal, non-commercial informational
          purpose and do not copy or post such information on any networked
          computer or broadcast it in any media.
        </p>
        <br />
        <p>
          You may not attempt to gain unauthorized access to any portion or
          feature of the Website, or to any our server, or to any of the
          Services offered on or through the Website, by hacking, password
          brute- forcing or any other illegitimate means.
        </p>
        <br />
        <p>
          You may not reverse look-up, trace or seek to trace any information on
          any other user of or visitor to the Website, or any other our
          customer, including any Account not owned by you, to its source, or
          exploit the Website or any service or information made available or
          offered by or through the Website, in any way where the purpose is to
          reveal any information, including but not limited to personal
          identification or information, other than your own information, as
          provided for by the Website.
        </p>
        <br />
        <p>
          You may not use the Website or any its content, Services, Applications
          and/or Data for any purpose that is unlawful or prohibited by this
          Agreement, or to solicit the performance of any illegal activity or
          other activity, which infringes our or others rights.
        </p>
        <br />
        <p>
          Subject to your compliance with this Agreement, we grant you a
          revocable, limited, non-exclusive, non-transferable and
          non-sublicensable license to access and use the Website, Services,
          Applications and Data available via the Services solely for your
          personal purposes.
        </p>
        <br />
        <p>
          You hereby grant us the right to access, process and use your Client
          Data with the aim to deliver it to you through the Services and in
          non-identifiable form to other our clients.
        </p>
        <br />
        <p>
          We reserve the right to do any of the following, at any time, without
          notice, to: modify, suspend or terminate operation or access to the
          Website, Applications or Services for any reason; modify or change the
          Website, Services, Applications and any applicable policies or terms;
          and/or interrupt the operation of the Website, Services and/or
          Applications as necessary to perform routine or non-routine
          maintenance, error correction, or other changes.
        </p>
        <br />
        <h3>Security policy</h3>

        <p>
          By using Website, Services or Applications you signify your acceptance
          of our security policy available on the Website.
        </p>

        <h3>Privacy policy</h3>

        <p>
          By using Website, Services or Applications you signify your acceptance
          of our privacy policy.
        </p>
        <br />
        <p>
          We collect information that you share to us yourself, e.g. when you
          complete Account registration form and enter API keys to the
          Application. This information may include, but is not limited to, your
          first and last name(s), company name and info (if any), email address,
          your account balance, trading activity, account history and
          identification data of the device on which the Application has been
          installed.
        </p>
        <br />
        <p>
          We may process information that you have shared for the following
          purposes: (a) execution, fulfilment, handling and administration of
          this Agreement; (b) billing and reconciliation; (c) maintenance,
          support and product/service development; (d) sales, revenue and
          customer analysis and reporting as well as for market and customer use
          analysis; and (e) carrying out identity, anti-money laundering and
          fraud prevention checks against your name using databases kept by
          other organizations and watch lists (which may involve giving the
          provided personal data to fraud prevention agencies who may keep and
          use it).
        </p>
        <br />
        <p>
          We only use your e-mail address for sharing our product related
          marketing messages if you have given a respective consent on the
          Website or in the Application. If you no longer wish to receive direct
          marketing messages, please click the Unsubscribe from direct marketing
          messages link in the footer of our e-mail.
        </p>
        <br />
        <p>
          We reserve the right at all times to disclose any information that we
          deem necessary to comply with any applicable law, regulation, legal
          process or governmental request.
        </p>
        <br />
        <p>
          Any personal data collected in the course of providing the Services is
          transferred to and stored in the data centers located in the territory
          of the European Union. Only our authorized employees and/or service
          providers have access to the personal data and they may access the
          data only for the purposes provided above.
        </p>
        <br />
        <p>
          You may request details of personal information which we hold about
          you in accordance with data protection laws. If you believe that any
          information we are holding on you is incorrect or incomplete, please
          write to or email us as soon as possible. In addition, you have all
          other rights provided in applicable laws regulating data protection
          and electronic transmission of commercial notifications.
        </p>
        <br />
        <p>
          Please note that the deinstallation of Application in your device does
          not cause the deletion of your personal data. If you want to delete
          your personal data collected by us, please send a written request by
          email as indicated above. You should also bear in mind that the
          request to delete personal data is possible only if we delete your
          Account. As a result of that you will not be able to use the Services
          under your existing Account.
        </p>
        <br />
        <p>
          We adhere to the generally accepted industry standards to protect the
          information you submit to us. You agree and acknowledge that the
          Internet may be subject to breaches of security and that the
          submission of data over the Internet may not be secure.
        </p>
        <br />
        <h3>Cookie policy</h3>

        <p>
          Like most Internet sites, our Website may use cookies. Cookies are
          small text files placed on the browser or hard drive of your computer,
          mobile, or other device when you visit a website. Cookies are widely
          used when providing online services and help to make websites work, or
          work more efficiently as well as to provide information to the website
          owners. Cookies do lots of different things, for example, remembering
          user preferences, letting users navigate between pages efficiently and
          generally improving the user experience. Cookies are not used to
          personally identify you in any way, nor will they damage your system
          or files.
        </p>
        <br />
        <p>
          We may use on our Website the strictly necessary cookies that enable
          you to log into secure areas of Website and cookies used by our
          networking system, analytical cookies that collect information about
          how you use Website, advertising cookies, session cookies, persistent
          cookies and third-party cookies.
        </p>
        <br />
        <p>
          It is possible to tell your browser to reject cookies for all sites or
          specific sites. Rejecting cookies is not however recommended, as
          cookies are intended to improve your browsing experience. In order to
          reject or opt out of cookies you can either manually delete them or
          choose to permanently opt-out from seeing advertisements matching your
          interests.
        </p>
        <br />
        <h3>Liability, indemnity</h3>
        <br />
        <p>
          You are fully responsible for the due performance of your obligations
          under the Agreement and must compensate to us any and all damages
          caused by the non-performance or unsatisfactory performance of your
          obligations.
        </p>
        <br />
        <p>
          To the fullest extent permitted by applicable law, you agree to
          indemnify, hold and defend us, our officers, directors, shareholders,
          predecessors, successors in interest, employees, agents, clients and
          partners, harmless from any demands, loss, liability, claims or
          expenses, made against us by any and all third-party claims and
          liabilities arising out of or related to or in connection with your
          use of the Website, Services, Applications and/or Data, including any
          served content that is not provided by us, or your breach of any term
          of the Agreement. You shall notify us immediately of any matter which
          could result in any loss, claim, damage, expense or liability subject
          to indemnification under this section. Such notification will not
          release you from your indemnification duty. We reserve the right to
          exercise sole control over the defense, at your expense, of any claim
          subject to indemnification under this section.
        </p>
        <br />
        <h3>Disclaimer of warranty</h3>
        <br />
        <p>
          We do not promise that our Website or any content, Services,
          Applications and/or Data or feature will be error-free or
          uninterrupted, or that any defects will be corrected, or that your use
          of the Website, Services, Applications and/or Data will provide
          specific results. The Website and its content, our Services,
          Applications and/or Data are delivered on an as is and as available
          basis. All information provided on the Website is subject to change
          without notice. We disclaim all warranties, express or implied,
          including any warranties of accuracy, non-infringement,
          merchantability, fitness for a particular purpose, quality or
          performance. We make no warranty that the Website, Applications and/or
          Data will meet your specific objectives or needs. We make no warranty
          that the Website, Services, Applications and/or Data will be free from
          errors or bugs. We make no warranty that there will be uninterrupted
          operation of the Services, Website, Applications and/or Data.
        </p>
        <br />
        <p>
          We make no warranty regarding the Data or any other information
          purchased or obtained through the Website, Applications and/or the
          Services, or the accuracy, timeliness, truthfulness, completeness or
          reliability of any Data or other information obtained through the
          Website, Applications and/or the Services.
        </p>
        <br />
        <p>
          You expressly acknowledge that any data downloaded through the use of
          the Services and Website is done at your own discretion and risk, and
          that you will be solely responsible for any damage to your computer
          system or loss of applications or data that results from the download
          of such data.
        </p>
        <br />
        <p>
          We disclaim any and all liability for the acts, omissions and any
          conduct of any third parties in connection with or related to your use
          of the Website, Services, Applications and/or Data. You acknowledge
          that we are not a financial institution and do not give or provide you
          any advice on your trading activity and disclaim any liability for
          execution of your trading orders. Your sole remedy against us for
          dissatisfaction with the Website or any its content, Services,
          Applications and/or Data is to stop using the Website or any such
          content, Services, Applications and/or Data.
        </p>
        <br />
        <p>
          The above disclaimer applies to any damages, liability or injuries
          caused by any failure of performance, error, omission, interruption,
          deletion, defect, delay in operation or transmission, computer virus,
          communication line failure, theft or destruction of or unauthorized
          access to, alteration of, or use, whether for breach of contract,
          tort, negligence or any other cause of action.
        </p>
        <br />
        <p>
          No claim for a breach of any our representation or warranty shall be
          actionable or payable if the breach in question results from or is
          based on a condition, state of facts or other matter which was
          disclosed to you and/or actually known by you.
        </p>
        <br />
        <p>
          In no event will we be liable for any indirect, special, incidental,
          consequential or punitive loss, injury or damage of any kind
          (regardless of whether we have been advised of the possibility of such
          loss) including, but not limited to, any loss of revenue, income or
          profits, loss of use or data, or damages for business interruption,
          any damages resulting from any interruption or disruption in
          communications or Services or Applications, unavailability or
          inoperability of the Services, Applications and/or Data. Our liability
          arising under this Agreement, whether in contract, tort (including
          negligence) or for breach of statutory duty or in any other way shall
          only be for direct damages and shall not exceed the lower of EUR 5000
          or the Fee payable by you to us over the three-month period
          immediately preceding the date of the claim.
        </p>
        <br />
        <p>
          The provision of this section 9 determine the allocation of risks
          between you and us, and you agree and acknowledge that such allocation
          of risks and the limitations of liability specified herein are an
          essential basis of the bargain between you and us.
        </p>
        <br />
        <p>
          The above exclusions of liability shall be valid to the fullest extent
          permitted by law. Some jurisdictions do not allow the exclusion of
          certain warranties in certain circumstances. Accordingly, some of the
          limitations set forth above may not apply.
        </p>
        <br />

        <h3>Force majeure</h3>
        <br />
        <p>
          You and us shall not be liable for delays or failure to perform under
          the Agreement which result directly or indirectly from any cause or
          condition beyond its reasonable control, including but not limited to,
          any delay or failure due to any act of God, act of civil or military
          authorities, act of terrorists, civil disturbance, war, strike or
          other labour dispute, fire, interruption in telecommunications or
          Internet services or network provider services, failure of equipment
          and/or software, other catastrophe or any other occurrence which is
          beyond its reasonable control and shall not affect the validity and
          enforceability of any remaining provisions (force majeure).
        </p>
        <br />
        <p>
          The party in default of force majeure shall immediately notify the
          other party of the occurrence of force majeure, shall make reasonable
          efforts to remove or overcome the effects of such occurrence or event;
          and shall resume performance of its obligations hereunder immediately
          after cessation of such occurrence or event.
        </p>
        <br />
        <h3>Duration and Termination</h3>

        <p>
          Your Agreement with us becomes effective upon registration of your
          Account or upon signing any additional agreement with us and is valid
          for unspecified period.
        </p>
        <br />
        <p>
          Each party may terminate this Agreement for any reason, by notifying
          the other party at least 24 hours in advance.
        </p>
        <br />
        <p>
          You are not entitled to a refund on Fees paid to us if you terminate
          the Agreement before the expiration of the subscription term of the
          Subscription Plan purchased by you, except for if the termination is
          due to a material breach of the Agreement by us as provided below, in
          which case we will the prorated Fees associated with the unused
          portion of the Services.
        </p>
        <br />
        <p>
          We will refund you the prorated Fees associated with the unused
          portion of the Services if we terminate the Agreement before the
          expiration of the subscription term of the Subscription Plan purchased
          by you, except for if the termination is due to the reasons provide
          below. We may at any time with immediate effect suspend or cease
          supplying the Service or terminate the Agreement if:
        </p>
        <br />
        <ul>
          <li>
            we are required so by a facially valid subpoena, court order, or
            binding order of a governmental authority;
          </li>
          <li>
            you are in delay with payment of the Fee and such delay has lasted
            at least 5 days;
          </li>
          <li>
            you have failed to comply with any applicable laws, directives,
            rules and/or regulations;
          </li>
          <li>
            you have materially breached any other obligation of the Agreement
            and failed to remedy such breach within a reasonable time granted by
            us;
          </li>
          <li>
            in respect of you, a liquidator, receiver or administrative receiver
            is appointed, you are adjudicated as bankrupt, or declared as
            insolvent;
          </li>
          <li>
            this opportunity is set forth in any other provision of this
            Agreement.
          </li>
        </ul>

        <br />

        <p>
          You may terminate the Agreement if we commit a material breach of the
          Agreement and fail to rectify such breach within fifteen (15) days
          after receipt of your written notice requesting such rectification.
        </p>
        <br />
        <p>
          A termination notice must be sent by registered mail (with notice of
          delivery) or by e-mail, sent by you to support@Relic Investment.com or
          by e-mail from us to the e-mail address you have previously provided.
        </p>
        <br />
        <p>
          Upon termination of the Agreement your Account is immediately
          archived. All provisions of the Agreement which by their nature extend
          beyond the expiration or termination of this Agreement shall survive
          the termination of this Agreement. Termination of this Agreement will
          not prejudice any right of action or remedy which may have accrued to
          you or us prior to termination (as the case may be).
        </p>
        <br />

        <h3>Notices</h3>

        <p>
          You agree that we may communicate with you electronically any finance
          information related to your Account. We may also provide notices to
          you by posting them in your Account on the Website, or by sending them
          to an email address that you have previously provided to us. Website
          and email notices shall be considered received by you within 24 hours
          of the time posted or sent.
        </p>
        <br />
        <h3>Complaints</h3>
        <br />
        <p>
          In case you have any complaints, or require additional support, please
          contact us by e-mail at support@PrimeFX Pro.com. Our support team will
          get back to you within 48 working hours. If your inquiry requires a
          more detailed answer, it might take up to 30 working days for
          processing your request.
        </p>
        <br />
        <h3>Governing law and jurisdiction</h3>

        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of the Republic of Estonia without regard to its principles
          and rules on conflict of laws.
        </p>
        <br />
        <p>
          Any dispute arising out or in connection with this Agreement will be
          finally settled by the Arbitration Court of the Estonian Chamber of
          Commerce and Industry in Tallinn on the basis of the regulation of the
          aforementioned Arbitration Court. The arbitral tribunal proceedings
          will be conducted in English. The arbitration will be held by one
          arbitrator mutually agreed upon by the Parties, and if no agreement
          can be reached, then by one arbitrator who is chosen by the Council of
          the Arbitration Court of the Estonian Chamber of Commerce and
          Industry.
        </p>
        <br />
        <p>
          Any claim under the Agreement must be brought within six (6) months
          after the cause of action arises, or such claim or cause of action is
          barred.
        </p>
        <br />

        <h3>Miscellaneous</h3>
        <br />
        <p>
          There is no relationship of exclusivity, partnership, joint venture,
          employment, agency or franchise between you and us under this
          Agreement. None of us has the authority to bind the each other
          (including the making of any representation or warranty, the
          assumption of any obligation or liability and/or the exercise of any
          right or power), except as expressly provided in this Agreement.
        </p>
        <br />
        <p>
          Whenever possible, each provision of this Agreement will be
          interpreted in such a manner as to be effective and valid under
          applicable law but, if any provision of this Agreement is held to be
          invalid, illegal or unenforceable in any respect, such provision will
          be ineffective only to the extent of such invalidity, or
          unenforceability, without invalidating the remainder of this Agreement
          or any other provision hereof.
        </p>
        <br />
        <p>
          This Agreement, including the Subscription Plan, as amended from time
          to time according to its terms, constitutes the full and entire
          understanding and agreement between you and us regarding the subjects
          hereof and supersedes all prior agreements, arrangements,
          representations or promises, whether oral or written, as to its
          subject matter.
        </p>
        <br />
        <p>
          Our failure to insist on or enforce strict performance of the terms
          and conditions of the Agreement shall not be construed as a waiver by
          us of any provision or any right it has to enforce the Agreement, nor
          shall any course of conduct between us and you or any other party be
          deemed to modify any provision of the terms and conditions of the
          Agreement.
        </p>
        <br />
        <p>
          This Agreement, and any rights and obligations hereunder, may not be
          transferred or assigned by you, but may be assigned by us without
          restriction, including without limitation to any third party. Any
          attempted transfer or assignment in violation hereof shall be null and
          void. Subject to the foregoing, this Agreement will bind and inure to
          the benefit of us and you, respective successors and permitted
          assigns.
        </p>
        <br />
        <p>
          A person who is not a party to this Agreement has no right to rely
          upon or enforce any term of this Agreement.
        </p>
        <br />
        <p>Last updated: January 1, 2023 at 24:00 pm</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>