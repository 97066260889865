<template>
  <div class="account-create-process-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-8 col-lg-9 col-md-12">
          <div class="account-create-process-content">
            <div class="section-title">
              <h2>Our Values</h2>
              <p>
                Trading and Profiting from forex have never been this easy yet
                effective, let's begin your Forex success story here and take
                control of every trade, deposit and withdrawal.
              </p>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-process-box">
                  <div class="icon">
                    <img src="../../assets/img/icon/icon1.png" alt="image" />
                  </div>
                  <h3>Create Account</h3>
                  <p>
                    We've adopted the easiest yet secured and intutive process
                    yet, See for yourself.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-process-box">
                  <div class="icon">
                    <img src="../../assets/img/icon/icon2.png" alt="image" />
                  </div>
                  <h3>Make deopsit</h3>
                  <p>
                    Choose your preferred method of deposit fund intrnded for
                    trading.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-process-box">
                  <div class="icon">
                    <img src="../../assets/img/icon/icon3.png" alt="image" />
                  </div>
                  <h3>Trade and Profit</h3>
                  <p>
                    There is no limit to the possibilities of your returns of
                    investments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-3 col-md-12">
          <div class="account-create-process-image text-center">
            <img src="../../assets/img/convert-currency.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurValues",
};
</script>