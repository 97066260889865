<template>
  <div class="app-download-area bg-fffbf1">
    <div class="container">
      <div class="row align-items-center m-0">
        <div class="col-lg-6 col-md-12 p-0">
          <div class="app-download-image">
            <img src="../../assets/img/app.png" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
          <div class="app-download-content">
            <h2>Trade On The Mobile App</h2>
            <p>
              Trade wherever and whenever with our inturtive and easy to use
              mobile App with suite of tools like individual and market
              portfolios, price alerts, customer support via live chat & More.
            </p>

            <div class="btn-box">
              <a
                href="https://play.google.com/store/apps"
                class="playstore-btn"
                target="_blank"
              >
                <img src="../../assets/img/play-store.png" alt="image" />
                Get It On
                <span>Google Play</span>
              </a>
              <a
                href="https://www.apple.com/store"
                class="applestore-btn"
                target="_blank"
              >
                <img src="../../assets/img/apple-store.png" alt="image" />
                Download on the
                <span>Apple Store</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shape12">
      <img src="../../assets/img/shape/shape12.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileApp",
};
</script>