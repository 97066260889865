<template>
    <div class="sell-cryptocurrency-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="sell-cryptocurrency-content">
                        <h1>
                            <span>Sell Bitcoin</span>
                            <span>BTC ($55,531.70)</span>
                        </h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis egnostrud exercitation ullamco.</p>
                        <a href="#" class="link-btn"><i class="fas fa-caret-right"></i> Read More About Bitcoin</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <CurrencySelect />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CurrencySelect from './CurrencySelect'

export default {
    name: 'SellCryptocurrency',
    components: {
        CurrencySelect
    },
}
</script>