<template>
  <div :class="['navbar-area', { 'is-sticky': isSticky }]">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img src="../../assets/img/logo.png" alt="logo" />
        </router-link>
        <div
          class="navbar-toggler"
          @click="active = !active"
          :aria-pressed="active ? 'true' : 'false'"
          v-bind:class="{ active: button_active_state }"
          v-on:click="button_active_state = !button_active_state"
        >
          <i class="fas fa-bars"></i>
          <i class="fas fa-times"></i>
        </div>
        <div class="collapse navbar-collapse" :class="{ toggler: active }">
          <ul class="navbar-nav">
            <!-- <li class="nav-item">                        
                            <a href="#" class="dropdown-toggle nav-link">
                                Home
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link">
                                        Home Demo - 1
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/home-two" class="nav-link">
                                        Home Demo - 2
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/home" class="nav-link">
                                        Home Demo - 3
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="dropdown-toggle nav-link">Pages</a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <router-link to="/trade" class="nav-link">
                                                Trade
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/leadership" class="nav-link">
                                                Leadership Team
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/privacy-policy" class="nav-link">
                                                Privacy Policy
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/terms-conditions" class="nav-link">
                                                Terms & Conditions
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> -->
            <li class="nav-item">
              <router-link to="/" class="nav-link"> Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link"> About Us</router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="dropdown-toggle nav-link"> Trade</a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <router-link to="/wallet" class="nav-link">
                    Markets</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/prices" class="nav-link">
                    Cryptocurrency
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- <li class="nav-item megamenu">
              <a href="#" class="dropdown-toggle nav-link">Sell</a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <router-link to="/sell" class="nav-link">
                    <img
                      src="../../assets/img/cryptocurrency/cryptocurrency4.png"
                      alt="image"
                    />
                    XRP - Ripple
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/sell" class="nav-link">
                    <img
                      src="../../assets/img/cryptocurrency/cryptocurrency5.png"
                      alt="image"
                    />
                    STE - Stellar
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/sell" class="nav-link">
                    <img
                      src="../../assets/img/cryptocurrency/cryptocurrency2.png"
                      alt="image"
                    />
                    BTC - Bitcoin
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/sell" class="nav-link">
                    <img
                      src="../../assets/img/cryptocurrency/cryptocurrency3.png"
                      alt="image"
                    />
                    MIT - Litecoin
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/cryptocurrency" class="nav-link">
                    <i class="bx bxs-chevron-right-circle"></i>
                    View All Coins
                  </router-link>
                </li>
              </ul>
            </li> -->
            <!-- <li class="nav-item">
              <router-link to="/prices" class="nav-link">
                Listings
              </router-link>
            </li> -->
            <li class="nav-item megamenu">
              <a href="#" class="dropdown-toggle nav-link">Help Centre</a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <router-link to="/faq" class="nav-link">
                    <i class="bx bx-info-circle"></i>
                    FAQ
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/guides" class="nav-link">
                    <i class="bx bx-book"></i>
                    Guides
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/affiliate-program" class="nav-link">
                    <i class="bx bx-paper-plane"></i>
                    Affiliates
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">
                Contact Us</router-link
              >
            </li>
          </ul>
        </div>

        <div class="others-option">
          <div class="d-flex align-items-center">
            <div class="option-item">
              <!-- <router-link to="/login-register" class="login-btn">
                <i class="bx bx-log-in"></i>
                Login
              </router-link> -->

              <a
                href="https://Terracore-inv.com/app/en/login.php"
                target=""
                class="login-btn"
                ><i class="bx bx-log-in"></i>Login
              </a>
            </div>
            <div class="option-item">
              <!-- <button class="default-btn text-link">
                <i class="bx bxs-user"></i
                ><a href="https://Terracore.com/register" class="text-link"
                  >Register</a
                >
              </button> -->
              <a
                href="https://Terracore-inv.com/app/en/register.php"
                target=""
                class="default-btn"
                ><i class="bx bxs-user"></i>Register
              </a>
            </div>
            <!-- <div class="option-item">
              <select class="form-select">
                <option selected>English</option>
                <option value="1">Türkçe</option>
                <option value="2">Español</option>
                <option value="3">한국어</option>
                <option value="4">Italiano</option>
                <option value="5">Polski</option>
              </select>
            </div> -->
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>