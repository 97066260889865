<template>
  <div class="payment-method-area ptb-100 bg-f9f9f9">
    <div class="container">
      <div class="section-title">
        <h2>Our Withdrawal Methods</h2>
        <p>Withdraw how you want, When you want</p>
      </div>

      <ul class="payment-method-list">
        <li title="Visa">
          <img src="../../assets/img/payment/visa.png" alt="image" />
        </li>
        <li title="iDeal">
          <img src="../../assets/img/payment/ideal.png" alt="image" />
        </li>
        <li title="PayPal">
          <img src="../../assets/img/payment/paypal.png" alt="image" />
        </li>
        <li title="Google Pay">
          <img src="../../assets/img/payment/google-pay.png" alt="image" />
        </li>
        <li title="American Express">
          <img
            src="../../assets/img/payment/american-express.png"
            alt="image"
          />
        </li>
        <li title="Apple Pay">
          <img src="../../assets/img/payment/apple-pay.png" alt="image" />
        </li>
        <li title="Stripe">
          <img src="../../assets/img/payment/stripe.png" alt="image" />
        </li>
        <li title="Giro Pay">
          <img src="../../assets/img/payment/giro-pay.png" alt="image" />
        </li>
        <li title="Master Card">
          <img src="../../assets/img/payment/master-card.png" alt="image" />
        </li>
        <li title="Skrill">
          <img src="../../assets/img/payment/skrill.png" alt="image" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentMethods",
};
</script>