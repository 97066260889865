<template>
  <div class="best-seller-area pt-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-12 col-md-12">
          <div class="best-seller-image">
            <img src="../../assets/img/women.png" alt="image" />
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12">
          <div class="best-seller-content">
            <div class="section-title">
              <h2>{{ heading }}</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </p>
            </div>
            <div class="cryptocurrency-slides">
              <carousel
                :autoplay="5000"
                :settings="settings"
                :wrap-around="true"
                :breakpoints="breakpoints"
              >
                <slide v-for="slide in carouselsItems" :key="slide.id">
                  <div class="single-cryptocurrency-box">
                    <div class="d-flex align-items-center">
                      <img :src="slide.image" alt="image" />
                      <div class="title">
                        <h3>{{ slide.title }}</h3>
                        <span class="sub-title">
                          BTC - <span>{{ slide.price }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="btn-box">
                      <router-link to="/buy" class="link-btn">Buy</router-link>
                      <router-link to="/sell" class="link-btn"
                        >Sell</router-link
                      >
                    </div>
                  </div>
                </slide>

                <template #addons>
                  <Pagination />
                </template>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shape14">
      <img src="../../assets/img/shape/shape14.png" alt="image" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "BestSellers",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: ["heading"],
  data: () => ({
    carouselsItems: [
      {
        id: 1,
        image: require("../../assets/img/bitcoin.png"),
        title: "Bitcoin",
        price: "€41,905",
      },
      {
        id: 2,
        image: require("../../assets/img/ethereum.png"),
        title: "Ethereum",
        price: "50,000",
      },
      {
        id: 3,
        image: require("../../assets/img/cardano.png"),
        title: "Cardano",
        price: "38,541",
      },
      {
        id: 4,
        image: require("../../assets/img/peercoin.png"),
        title: "Peercoin",
        price: "€12,000",
      },
      {
        id: 5,
        image: require("../../assets/img/groestlcoin.png"),
        title: "Groestlcoin",
        price: "14,542",
      },
      {
        id: 6,
        image: require("../../assets/img/bitconnect.png"),
        title: "Bitconnect",
        price: "14,514",
      },
      {
        id: 7,
        image: require("../../assets/img/digibyte.png"),
        title: "Digibyte",
        price: "41,514",
      },
    ],
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    breakpoints: {
      0: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      576: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      768: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 3,
        snapAlign: "center",
      },
    },
  }),
});
</script>