<template>
  <div>
    <Navbar />
    <About />
    <Advisors />
    <OurValues />
    <StartTrading heading="Start Trading on Terracore" />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import About from "../About/About";
import Advisors from "../About/Advisors";
import OurValues from "../About/OurValues";
import StartTrading from "../Common/StartTrading";
import Footer from "../Layouts/Footer";

export default {
  name: "AboutPage",
  components: {
    Navbar,
    About,
    Advisors,
    OurValues,
    StartTrading,
    Footer,
  },
};
</script>