<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog1.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Filecoin</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    How Filecoin is Up 50% in a Week & Could Take More Profits
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user1.jpg" alt="image">
                                <router-link to="/blog">Steven Smith</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog2.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Education</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    Coinbase Releases Investor Education Resources in 2021
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user2.jpg" alt="image">
                                <router-link to="/blog">Sarah Taylor</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog3.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Bitcoin</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    World’s Largest Bitcoin Conference Coming To Miami in June
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user3.jpg" alt="image">
                                <router-link to="/blog">David Warner</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog4.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Bitcoin</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    Who Is Buying Bitcoin And Pushing Its Price Higher In 2021?
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user4.jpg" alt="image">
                                <router-link to="/blog">James Andy</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog5.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Crypto</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    Robinhood Or Coinbase? Which is Better For Crypto?
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user5.jpg" alt="image">
                                <router-link to="/blog">David Wisey</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog6.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Gamble</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    Can You Use Cryptocurrency to Gamble Online in 2021?
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user6.jpg" alt="image">
                                <router-link to="/blog">Lucy Donar</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <span class="page-numbers current">1</span>
                            <router-link to="/blog" class="page-numbers">2</router-link>
                            <router-link to="/blog" class="page-numbers">3</router-link>
                            <router-link to="/blog" class="next page-numbers" title="Next Page"><i class='bx bx-chevrons-right'></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>