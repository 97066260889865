<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Bitcoin Price (BTC)" />
        <ListingsDetails />
        <StartTrading heading="Earn Up To $55 Worth Of Crypto" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import ListingsDetails from '../ListingsDetails/ListingsDetails'
import StartTrading from '../Common/StartTrading'
import Footer from '../Layouts/Footer'

export default {
    name: 'ListingsDetailsPage',
    components: {
        Navbar,
        PageTitle,
        ListingsDetails,
        StartTrading,
        Footer,
    }
}
</script>