<template>
  <div class="features-area pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>The Global Leader of Forex & indices Trading</h2>
        <p>
          Trade with a customisable multi-asset trading platform. Offering CFDs
          on forex, commodities, cryptocurrencies, and synthetics, which gives
          you a versatile trading experience that lets you customise your
          trading environment.
        </p>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="features-box">
            <div class="icon">
              <img src="../../assets/img/icon/icon18.png" alt="image" />
            </div>
            <h3>Trade Smartly</h3>
            <p>
              Benefit from round-the-clock trading hours (Monday to Friday),
              high liquidity, low barriers to entry, a wide range of offerings,
              and opportunities to trade on world events.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="features-box">
            <div class="icon bg-ffefc7">
              <img src="../../assets/img/icon/icon19.png" alt="image" />
            </div>
            <h3>Trade Cryptocurrency</h3>
            <p>
              Take advantage of a highly liquid market with round-the-clock
              trading. Profit from correctly predicting the movement of world's
              most popular cryptocurrencies and more.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="features-box">
            <div class="icon bg-ffc9c2">
              <img src="../../assets/img/icon/icon20.png" alt="image" />
            </div>
            <h3>Auto Trader</h3>
            <p>
              Trade hands free with an automated forex trading software &
              Execute logical, profitable trades almost instantly when the
              opportunity arises and then posts the profit to your account.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalLeader",
};
</script>