<template>
  <div class="banner-wrapper-area portfolio-area">
    <div class="container">
      <div class="row align-items-center m-0">
        <div class="col-xl-5 col-lg-6 col-md-12 p-0">
          <div
            class="content-slides text-center-s text-start-lg justify-content-center-s p-0 mt-0 pt-0"
          >
            <carousel :autoplay="5000" :settings="settings" :wrap-around="true">
              <slide v-for="slide in carouselsItems" :key="slide.id">
                <div class="content banner-wrapper-content">
                  <span class="sub-title">100% Supercharged Bonus </span>
                  <h1>{{ slide.heading }}</h1>
                  <p>{{ slide.description }}</p>
                  <button class="default-btn btn-lg-s text-link">
                    <i class="bx bxs-user"></i
                    ><a
                      href="https://Terracore-inv.com/app/en/register.php"
                      class="text-link"
                      >Create a free account</a
                    >
                  </button>
                </div>
              </slide>

              <template #addons>
                <Navigation />
              </template>
            </carousel>
          </div>
        </div>
        <div class="col-xl-7 col-lg-6 col-md-12 p-0">
          <div class="banner-wrapper-image">
            <img src="../../assets/img/banner/banner-img3.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//export default {
//  name: "MainBanner",
//};
//==============================================================================

import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "ManagePortfolio",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    carouselsItems: [
      {
        id: 1,
        heading: "No 1 Active and Multi-Asset Trading Platform",
        description:
          "Trade forex, synthetics, stocks & indices, cryptocurrencies and commodities. We are an award winning forex and commodities broker, providing trading services and facilities to both retail and institutional clients.",
      },
      {
        id: 1,
        heading: "Trade on the most secured and trusted Platform!",
        description:
          "Join millions of happy investors on Terracore Maximizing your ROI while minimizing risk and losses thereby reducing the entry level barrier to zero, for new and inexperienced Traders",
      },
      // {
      //   id: 1,
      //   heading: "Vault Protection",
      //   description:
      //     "We implement the most advanced financial protection and risk management systems to ensure the safety of users' transactions on our platform.",
      // },
    ],
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
  }),
});
</script>

