<template>
  <div class="fedback-area pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Trusted by Millions</h2>
        <p>Join millions of users & start Trading</p>
      </div>
      <div class="feedback-slides">
        <carousel
          :autoplay="5000"
          :breakpoints="breakpoints"
          :settings="settings"
        >
          <slide v-for="slide in carouselsItems" :key="slide.id">
            <div class="single-feedback-item">
              <div class="rating">
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
              </div>
              <p>{{ slide.description }}</p>
              <div class="client-info">
                <div class="d-flex align-items-center">
                  <img :src="slide.image" alt="image" />
                  <div class="title">
                    <h3>{{ slide.name }}</h3>
                    <span>{{ slide.position }}</span>
                  </div>
                </div>
              </div>
            </div>
          </slide>

          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "FeedbackSlider",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data: () => ({
    carouselsItems: [
      {
        id: 1,
        description:
          "Terracore is a popular and growing company that specialize in helping others. I've been investing with them for only two years and my family's quality of life has improved so much. I'm very grateful for the opportunity to invest with Terracore.",
        image: require("../../assets/img/user/user1.jpg"),
        name: "Michael E. Allen",
        position: "Power line repairer",
      },
      {
        id: 2,
        description:
          "I don't know how to express my appreciation for this opportunity. I needed a way to invest my money but still have time for my family, and this was the perfect solution. Terracore have the best support team and opportunity available. I'm glad I made the right decision.",
        image: require("../../assets/img/user/user2.jpg"),
        name: "Lindsey R. Simmons",
        position: "Safety Specialist",
      },
      {
        id: 3,
        description:
          "I've had an excellent experience with Terracore. I know it's a little strange to talk about investments in this forum, given the subject matter of this site, but I can say that the people at Terracore were incredibly helpful and were able to guide me through the process of investing my money. They were very helpful and patient with all my questions and concerns, as I didn't feel like a novice.",
        image: require("../../assets/img/user/user3.jpg"),
        name: "Chris S. Dubose",
        position: "Auto Damage Appraiser",
      },
      {
        id: 4,
        description:
          "Terracore has created an investment strategy that is completely unique and it has been very successful in the last few years. I've invested in their services and saw a positive return on my investments.",
        image: require("../../assets/img/user/user7.jpg"),
        name: "Jesse M. Anderson",
        position: "Posting clerk",
      },
      {
        id: 5,
        description:
          "Terracore will help you make a smart and profitable investment. I'm investing with Terracore for a few years now. Every quarter I receive dividends from my initial investment. Thank you Terracore for the opportunity to earn an extra income in my free time!",
        image: require("../../assets/img/user/user5.jpg"),
        name: "Alina Smith",
        position: "Designer",
      },
      {
        id: 6,
        description:
          "I never believed in the economic collapse until it actually happened, but I am so glad that I did my research and invested in Terracore. I have been able to almost triple my money since my initial investment, and now I am going to use their monthly loans to replace all of my old investments with stocks, bonds and mutual funds.",
        image: require("../../assets/img/user/user6.jpg"),
        name: "Kelly K. Michalski",
        position: "Elevator repairer",
      },
      {
        id: 7,
        description:
          "Terracore proved to be a worthwhile investment for both my family and I as we were able to survive the 2020 pandemic. We used the profits from our initial investments to purchase additional supplies which helped us through the crisis. Although we still lost loved ones, we could have been in much worse condition had we not invested in Terracore.",
        image: require("../../assets/img/user/user4.jpg"),
        name: "Delbert S. Adams",
        position: "Lawn Care",
      },
    ],
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    breakpoints: {
      0: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      576: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      768: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      1200: {
        itemsToShow: 3,
        snapAlign: "center",
      },
    },
  }),
});
</script>