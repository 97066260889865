<template>
  <div class="portfolio-area">
    <div class="container">
      <div class="single-portfolio-item">
        <div class="row align-items-center m-0">
          <div class="col-xl-5 col-lg-6 col-md-12 p-0">
            <div class="content-slides">
              <carousel
                :autoplay="5000"
                :settings="settings"
                :wrap-around="true"
              >
                <slide v-for="slide in carouselsItems" :key="slide.id">
                  <div class="content">
                    <h3>{{ slide.heading }}</h3>
                    <p>{{ slide.description }}</p>
                  </div>
                </slide>

                <template #addons>
                  <Navigation />
                </template>
              </carousel>
            </div>
          </div>
          <div class="col-xl-7 col-lg-6 col-md-12 p-0">
            <div class="image text-center">
              <img
                src="../../assets/img/portfolio/portfolio-img1.png"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "ManagePortfolio",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    carouselsItems: [
      {
        id: 1,
        heading: "Manage Your Portfolio",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
      },
      {
        id: 1,
        heading: "Poerfull API",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
      },
      {
        id: 1,
        heading: "Vault Protection",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
      },
    ],
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
  }),
});
</script>