<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Our Latest Articles" />
        <BlogDetails />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogDetails from '../BlogDetails/BlogDetails'
import Footer from '../Layouts/Footer'

export default {
    name: 'AffiliateProgramPage',
    components: {
        Navbar,
        PageTitle,
        BlogDetails,
        Footer,
    }
}
</script>