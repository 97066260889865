<template>
  <footer class="footer-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-footer-widget">
            <router-link to="/" class="d-inline-block logo">
              <img src="../../assets/img/logo.png" alt="Image" />
            </router-link>
            <div class="newsletter-form">
              <p>SUBSCRIBE TO OUR NEWSLETTER</p>
              <form data-toggle="validator">
                <input
                  type="email"
                  class="input-newsletter"
                  placeholder="Enter your email"
                  name="EMAIL"
                  required
                  autocomplete="off"
                />
                <button type="submit">
                  Subscribe Now
                  <i class="bx bx-paper-plane"></i>
                </button>
                <div id="validator-newsletter" class="form-result"></div>
              </form>
            </div>
            <ul class="social-links">
              <li>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  class="facebook"
                >
                  <i class="bx bxl-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                  class="twitter"
                >
                  <i class="bx bxl-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  class="linkedin"
                >
                  <i class="bx bxl-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  class="instagram"
                >
                  <i class="bx bxl-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6 col-md-6">
          <div class="single-footer-widget">
            <h3>Useful Links</h3>
            <ul class="services-links">
              <li><router-link to="/home">Home</router-link></li>
              <li><router-link to="/about">Why Choose Us</router-link></li>
              <li><router-link to="/wallet">Markets</router-link></li>
              <li><router-link to="/faq">FAQs</router-link></li>
              <li>
                <router-link to="/terms-conditions"
                  >Terms & conditions</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
          <div class="single-footer-widget pl-5">
            <h3>Resources</h3>
            <ul class="quick-links">
              <li><router-link to="#">Forex</router-link></li>
              <li><router-link to="#"> Stocks</router-link></li>
              <li><router-link to="#">Synthetics</router-link></li>
              <li><router-link to="#"> Indices</router-link></li>
              <li>
                <router-link to="#">Commodities</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
          <div class="single-footer-widget">
            <h3>Contact Info</h3>
            <ul class="footer-contact-info">
              <li>1055 West 7th Street, 33rd Floor, Los Angeles, CA 90017</li>
              <li>
                Email:
                <a href="mailto:info@Terracore-inv.com"
                  >info@Terracore-inv.com</a
                >
              </li>
              <li>Phone: <a href="tel:+3058747784">+1 (216) 243-0012</a></li>
              <li>Fax: <a href="tel:+3058747784">+1 (216) 243-0012</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-area">
      <div class="container">
        <p>
          Copyright {{ currentYear }} <strong>Terracore</strong>. All Rights
          Reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>