<template>
  <div class="features-area pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>{{ heading }}</h2>
        <p>
          Our history that dates back to 1999 when we laid the groundwork for
          the world’s first fixed-odds trading service. Since then, we have
          grown from strength to strength, garnering the trust of traders around
          the world.
        </p>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-features-box">
            <img
              src="../../assets/img/features/features-img1.png"
              alt="image"
            />
            <h3>Security First</h3>
            <p>
              Access our world-class trading platform and trade multi assets.
              Protection for deposits and withdrawals, and free bank transfers.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-features-box">
            <img
              src="../../assets/img/features/features-img2.png"
              alt="image"
            />
            <h3>Fast Withdrawals</h3>
            <p>
              Actually fast withdrawals, no delays or waiting for the bitcoins
              to arrive. These are the reasons why we have an outstanding
              reputation among our customers.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-6 mdw-100">
          <div class="single-features-box">
            <img
              src="../../assets/img/features/features-img3.png"
              alt="image"
            />
            <h3>24/7 Support</h3>
            <p>
              Customer support is our highest priority, should you have any
              questions at all simply contact us and we'll respond Instantly!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: ["heading"],
};
</script>