<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Frequently Asked Question" />
        <Faq />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Faq from '../Faq/Faq'
import Footer from '../Layouts/Footer'

export default {
    name: 'FaqPage',
    components: {
        Navbar,
        PageTitle,
        Faq,
        Footer,
    }
}
</script>