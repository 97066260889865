<template>
  <div class="wallet-area ptb-100">
    <div class="container">
      <div class="wallet-tabs">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-12">
            <div class="tabs__container">
              <div class="tabs__list" ref="tabsList">
                <div
                  class="tabs__list-tab"
                  v-for="tab in tabs"
                  v-bind:class="{ active: activeTab == tab.id }"
                  :key="tab.id"
                >
                  <div style="justfy-content: center; paddding-bottom: 20px">
                    <h2>Why Choose us</h2>
                  </div>
                  <div class="box">
                    <h3>{{ tab.heading }}</h3>
                    <p>{{ tab.description }}</p>
                    <ul class="features-list">
                      <li v-for="list in tab.lists" :key="list.id">
                        <i class="bx bx-check"></i>
                        {{ list.list }}
                      </li>
                    </ul>
                    <!-- <router-link to="/login-register" class="default-btn">
                      <i class="bx bxs-user"></i>
                      Learn More
                    </router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="tabs">
              <ul class="tabs__header">
                <li
                  class="tabs__header-item"
                  v-for="tab in tabs"
                  v-on:click="selectTab(tab.id)"
                  v-bind:class="{ active: activeTab == tab.id }"
                  :key="tab.id"
                >
                  <i :class="tab.icon"></i>
                  {{ tab.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletTab",
  data() {
    return {
      activeTab: 1,
      offsetRight: 0,
      tabs: [
        {
          id: 1,
          icon: "bx bx-shield-quarter",
          title: "Security",
          heading: " SECURE TRADING",
          description:
            "Incorporated in Saint Vincent & the Grenadines, Member of Coinium Markets Group, Only Major Banks are used, Audited by Deloitte, Full Transparency, Client funds are held in segregated accounts",
          lists: [
            {
              id: 1,
              list: "Best in Class system",
            },
          ],
        },
        {
          id: 2,
          icon: "bx bx-transfer",
          title: "Trading",
          heading: "SMART TRADING",
          description:
            "Performance based competitions with an Award winning affiliate program, 100% Credit Bonus, 100% Supercharged Bonus, 30% Rescue Bonus, Free Account Funding, Free Account Funding.",
          lists: [
            {
              id: 1,
              list: "Lowest fees",
            },
          ],
        },
        {
          id: 3,
          icon: "bx bx-dollar",
          title: "Platforms",
          heading: "CONVENIENT PLATFORM OPTIONS",
          description:
            "MT4 & MT5 Terminal for Desktop, MT4 & MT5 WebTerminal, MT4 MultiTerminal, iPhone Trader for MT4 & MT5, iPad Trader for MT4 & MT5, Android Trader for MT4 & MT5, RapidTrader API",
          lists: [
            {
              id: 1,
              list: "Easy to use",
            },
          ],
        },
        {
          id: 4,
          icon: "bx bx-globe",
          title: "Access Anywhere",
          heading: "Access Your Wallet Anywhere",
          description:
            "Financial markets in the palm of your hand with Terracore: use it to help you understand the markets with trading tools and the latest news, updates, and financial analysis.",
          lists: [
            {
              id: 1,
              list: "iOS, Android, Web",
            },
          ],
        },
      ],
    };
  },
  methods: {
    selectTab(id) {
      let tabsList = this.$refs.tabsList;
      this.activeTab = id;
      this.offsetRight = tabsList.clientWidth * (id - 1);
      tabsList.style.right = this.offsetRight + "px";
    },
  },
  mounted() {
    let tabsList = this.$refs.tabsList;
    tabsList.style.right = this.offsetRight + "px";
  },
};
</script>