<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>What is Bitcoin?</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-item">
                        <div class="icon">
                            <i class="fab fa-bitcoin"></i>
                        </div>
                        <h3>The Price of Bitcoin</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-item">
                        <div class="icon bg-F87100">
                            <i class="fas fa-wallet"></i>
                        </div>
                        <h3>Bitcoin Wallets</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-item">
                        <div class="icon bg-5466f9">
                            <i class="fas fa-shopping-cart"></i>
                        </div>
                        <h3>How to Buy Bitcoin</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-item">
                        <div class="icon bg-FF9900">
                            <i class="fas fa-object-ungroup"></i>
                        </div>
                        <h3>How to Sell Bitcoin</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-item">
                        <div class="icon bg-cc00ff">
                            <i class="fas fa-cubes"></i>
                        </div>
                        <h3>Block Explorers</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bitcoin'
}
</script>