<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Check Out Our Entire Coin Selection!" />
        <CryptocurrencySelection />
        <Features class="bg-f9f9f9" heading="How Do We Select Our Cryptocurrencies?" />
        <StartTrading />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import CryptocurrencySelection from '../Cryptocurrency/CryptocurrencySelection'
import Features from '../Common/Features'
import StartTrading from '../Cryptocurrency/StartTrading'
import Footer from '../Layouts/Footer'

export default {
    name: 'CryptocurrencyPage',
    components: {
        Navbar,
        PageTitle,
        CryptocurrencySelection,
        Features,
        StartTrading,
        Footer,
    }
}
</script>