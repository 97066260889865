<template>
    <div class="cryptocurrency-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Our Cryptocurrency Selection</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/bitcoin.png" alt="image">
                            <div class="title">
                                <h3>Bitcoin</h3>
                                <span class="sub-title">BTC - <span>€41,905</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/ethereum.png" alt="image">
                            <div class="title">
                                <h3>Ethereum</h3>
                                <span class="sub-title">BTC - <span>50,000</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/cardano.png" alt="image">
                            <div class="title">
                                <h3>Cardano</h3>
                                <span class="sub-title">BTC - <span>38,541</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/peercoin.png" alt="image">
                            <div class="title">
                                <h3>Peercoin</h3>
                                <span class="sub-title">BTC - <span>€12,000</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/groestlcoin.png" alt="image">
                            <div class="title">
                                <h3>Groestlcoin</h3>
                                <span class="sub-title">BTC - <span>14,542</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/bitconnect.png" alt="image">
                            <div class="title">
                                <h3>Bitconnect</h3>
                                <span class="sub-title">BTC - <span>14,514</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/digibyte.png" alt="image">
                            <div class="title">
                                <h3>Digibyte</h3>
                                <span class="sub-title">BTC - <span>41,514</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/ontology.png" alt="image">
                            <div class="title">
                                <h3>Ontology</h3>
                                <span class="sub-title">BTC - <span>14,514</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/omisego.png" alt="image">
                            <div class="title">
                                <h3>OmiseGO</h3>
                                <span class="sub-title">BTC - <span>41,514</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/qtum.png" alt="image">
                            <div class="title">
                                <h3>Qtum</h3>
                                <span class="sub-title">BTC - <span>€41,905</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/zcash.png" alt="image">
                            <div class="title">
                                <h3>ZCash</h3>
                                <span class="sub-title">BTC - <span>50,000</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/img/gulden.png" alt="image">
                            <div class="title">
                                <h3>Gulden</h3>
                                <span class="sub-title">BTC - <span>38,541</span></span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="link-btn">Buy</router-link>
                            <router-link to="/sell" class="link-btn">Sell</router-link>
                            <router-link to="/cryptocurrency-details" class="link-btn">Info</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CryptocurrencySelection'
}
</script>