<template>
    <div>
        <div class="blog-details-area ptb-100">
            <div class="container">
                <div class="blog-details-header row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="content">
                            <router-link to="/blog" class="category">Filecoin</router-link>
                            <h1>How Filecoin is Up 50% in a Week & Could Take More Profits</h1>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            <div class="post-author-meta">
                                <div class="d-flex align-items-center">
                                    <img src="../../assets/img/user/user1.jpg" alt="image">
                                    <div class="title">
                                        <span class="name">By 
                                            <router-link to="blog">EnvyTheme</router-link>
                                        </span>
                                        <span class="date">March 17, 2021</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="image">
                            <img src="../../assets/img/blog/blog1.jpg" alt="image">
                        </div>
                    </div>
                </div>
                <div class="blog-details-desc">
                    <div class="article-content">
                        <ul class="social-links">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank" class="facebook">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank" class="twitter">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/" target="_blank" class="linkedin">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank" class="instagram">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non jgstaa numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing voluptatem sequi nesciunt.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a href="#">commodo</a> consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        <h3>So, What Exactly is Positioning and Why Does It Matter?</h3>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                        <ul>
                            <li>a console</li>
                            <li>Two Joy-Con controllers that are detachable</li>
                            <li>A grip that enables you to combine them into a single <a href="#">gamepad</a> for play on the</li>
                            <li>Two straps for turning the Joy-Cons into individual controllers</li>
                            <li>A dock which you can use to connect your console to the <strong>television</strong> for traditional gameplay</li>
                        </ul>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non jgstaa numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing voluptatem sequi nesciunt.</p>
                        <h3>How We Defined Our Positioning: a Step-by-Step Overview</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable when content of a page when looking at its layout.</p>
                            <cite>Tom Cruise</cite>
                        </blockquote>
                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="../../assets/img/blog/blog4.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="../../assets/img/blog/blog5.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="../../assets/img/blog/blog6.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>
                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                        <ul>
                            <li>Scientific skills for getting a better result</li>
                            <li>Communication skills to getting in touch</li>
                            <li>A career overview opportunity available</li>
                            <li>A good work environment for work</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                        <div class="article-tags">
                            <router-link to="/blog">#Business</router-link>
                            <router-link to="/blog">#Startup</router-link>
                            <router-link to="/blog">#Agency</router-link>
                        </div>
                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave A Reply</h3>
                            <form class="comment-form">
                                <p class="comment-notes">Your email address will not be published. Required fields are marked <span class="required">*</span></p>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" name="name" class="form-control" placeholder="Eg: Sarah Taylor">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" name="email" class="form-control" placeholder="hello@sarah.com">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <input type="text" name="website" class="form-control" placeholder="Website">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Comment..."></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-5 col-sm-12">
                                        <button type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> Post A Comment</button>
                                    </div>
                                    <div class="col-lg-8 col-md-7 col-sm-12">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="checkme">
                                            <label class="form-check-label" for="checkme">Save my name, email, and website in this browser for the next time I comment.</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-area pt-100 pb-70">
            <div class="container">
                <div class="section-title">
                    <h2>You May Also Like</h2>
                </div>
                <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog1.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Filecoin</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    How Filecoin is Up 50% in a Week & Could Take More Profits
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user1.jpg" alt="image">
                                <router-link to="/blog">Steven Smith</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog2.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Education</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    Coinbase Releases Investor Education Resources in 2021
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user2.jpg" alt="image">
                                <router-link to="/blog">Sarah Taylor</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/img/blog/blog3.jpg" alt="image">
                            </router-link>
                            <div class="tag-list">
                                <router-link to="/blog">Bitcoin</router-link>
                            </div>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    World’s Largest Bitcoin Conference Coming To Miami in June
                                </router-link>
                            </h3>
                            <p>Borem ipsum dolor sit amet, adhuc iriure dissentias est in, est ne diam graece tincidunt.</p>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/img/user/user3.jpg" alt="image">
                                <router-link to="/blog">David Warner</router-link>
                            </div>
                            <router-link to="/blog-details" class="link-btn">
                                <i class='bx bx-right-arrow-alt'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogDetails'
}
</script>