<template>
  <div class="account-register-area ptb-100">
    <div class="container">
      <div class="account-register-content">
        <h2>Why choose us</h2>
        <p>
          Client trust is our highest priority, and that’s why millions of users
          choose us. Here are some of the things that make us a leading online
          trading service provider.
        </p>
        <a
          href="https://Terracore-inv.com/app/en/register.php"
          target=""
          class="default-btn"
          ><i class="bx bxs-user"></i> Create Account
        </a>
      </div>
    </div>
    <div class="shape6">
      <img src="../../assets/img/shape/shape6.png" alt="image" />
    </div>
    <div class="shape7">
      <img src="../../assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape8">
      <img src="../../assets/img/shape/shape8.png" alt="image" />
    </div>
    <div class="shape9">
      <img src="../../assets/img/shape/shape9.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedDollar",
};
</script>