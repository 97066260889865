<template>
    <div>
        <Navbar/>
        <TradeCryptocurrency />
        <BuyBitcoin />
        <BestSellers heading="Buy Popular Crypto Instantly" />
        <PaymentMethods />
        <FeedbackSlider />
        <StartTrading heading="Coin-to-Coin Trading" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import TradeCryptocurrency from '../Trade/TradeCryptocurrency'
import BuyBitcoin from '../Trade/BuyBitcoin'
import BestSellers from '../Common/BestSellers'
import PaymentMethods from '../Common/PaymentMethods'
import FeedbackSlider from '../Common/FeedbackSlider'
import StartTrading from '../Common/StartTrading'
import Footer from '../Layouts/Footer'

export default {
    name: 'TradePage',
    components: {
        Navbar,
        TradeCryptocurrency,
        BuyBitcoin,
        BestSellers,
        PaymentMethods,
        FeedbackSlider,
        StartTrading,
        Footer,
    }
}
</script>