<template>
    <div class="market-health-area pt-100">
        <div class="container">
            <div class="section-title">
                <h2>Market is Up <span>+5.24%</span></h2>
                <p>In the past 24 hours</p>
            </div>
            <ul class="market-health-list">
                <li>
                    <h3>Top Gainer (24h)</h3>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="title">
                            <img src="../../assets/img/cryptocurrency/bitcoin.png" alt="image">
                            <span class="name">Bitcoin</span>
                            <span class="status up">+25.28%</span>
                        </div>
                        <div class="price">
                            <span>$45.71</span>
                            <img src="../../assets/img/cryptocurrency/chart1.png" alt="image">
                        </div>
                    </div>
                </li>
                <li>
                    <h3>New Listing</h3>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="title">
                            <img src="../../assets/img/cryptocurrency/cardano.png" alt="image">
                            <span class="name">Cardano</span>
                            <span class="status">Added Mar 24</span>
                        </div>
                        <div class="price">
                            <span>$40.50</span>
                            <img src="../../assets/img/cryptocurrency/chart2.png" alt="image">
                        </div>
                    </div>
                </li>
                <li>
                    <h3>Highest Volume (24h)</h3>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="title">
                            <img src="../../assets/img/cryptocurrency/tether.png" alt="image">
                            <span class="name">Tether</span>
                            <span class="status">$4.95T</span>
                        </div>
                        <div class="price">
                            <span>$4,779,476.22</span>
                            <img src="../../assets/img/cryptocurrency/chart3.png" alt="image">
                        </div>
                    </div>
                </li>
                <li>
                    <h3>Most Visited (24h)</h3>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="title">
                            <img src="../../assets/img/cryptocurrency/usd-coin.png" alt="image">
                            <span class="name">USD Coin</span>
                            <span class="status up">+70.41% views</span>
                        </div>
                        <div class="price">
                            <span>$7,271.29</span>
                            <img src="../../assets/img/cryptocurrency/chart4.png" alt="image">
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarketHealth'
}
</script>