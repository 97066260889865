<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Our Affiliate Program" />
    <!-- <HowItWorks /> -->
    <EarnMoney />
    <Features heading="The Most Trusted Cryptocurrency Platform" />
    <StartTrading />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
// import HowItWorks from '../Affiliate/HowItWorks'
import EarnMoney from "../Affiliate/EarnMoney";
import Features from "../Common/Features";
import StartTrading from "../Common/StartTrading";
import Footer from "../Layouts/Footer";

export default {
  name: "AffiliateProgramPage",
  components: {
    Navbar,
    PageTitle,
    // HowItWorks,
    EarnMoney,
    Features,
    StartTrading,
    Footer,
  },
};
</script>