<template>
  <div class="cta-area bg-gradient-image pt-100">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="cta-content black-text">
            <h2>Not just a mission, it's a promise</h2>
            <p>
              Our Mission is To Provide the Best Customer Service to our Clients
              by maintaining a Superb Client Centric Culture. In this way we
              will achieve our Vision of maintaining our position as a Market
              Leader known for our Superior Customer Services.
            </p>
            <a
              href="https://Terracore-inv.com/app/en/register.php"
              target=""
              class="default-btn"
              ><i class="bx bxs-user"></i>Register Now
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="cta-image">
            <img src="../../assets/img/man-with-ipad.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div class="shape6">
      <img src="../../assets/img/shape/shape6.png" alt="image" />
    </div>
    <div class="shape7">
      <img src="../../assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape8">
      <img src="../../assets/img/shape/shape8.png" alt="image" />
    </div>
    <div class="shape9">
      <img src="../../assets/img/shape/shape9.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CryptoWallet",
};
</script>