<template>
    <div>
        <LoginRegister />
    </div>
</template>

<script>
import LoginRegister from '../LoginRegister/LoginRegister'

export default {
    name: 'LoginRegisterPage',
    components: {
        LoginRegister
    }
}
</script>