<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Start trading within Seconds" />
    <Guides />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import PageTitle from "../Common/PageTitle";
import Guides from "../Guides/Guides";
import Footer from "../Layouts/Footer";

export default {
  name: "GuidesPage",
  components: {
    Navbar,
    PageTitle,
    Guides,
    Footer,
  },
};
</script>