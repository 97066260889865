<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Our Leadership Team" />
        <Advisor />
        <StartTradingTwo />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Advisor from '../Team/Advisor'
import StartTradingTwo from '../Common/StartTradingTwo'
import Footer from '../Layouts/Footer'

export default {
    name: 'TeamPage',
    components: {
        Navbar,
        PageTitle,
        Advisor,
        StartTradingTwo,
        Footer,
    }
}
</script>