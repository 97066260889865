<template>
  <div class="about-area pb-100">
    <div class="container">
      <div class="about-content">
        <div class="content">
          <h1>Trade with the No1 Broker</h1>
          <p>
            Terracore is an award winning forex and commodities broker, providing
            trading services and facilities to both retail and institutional
            clients through its policy of providing the best possible trading
            conditions.
          </p>
        </div>
      </div>
      <div class="history-timeline timeline">
        <div class="section-title">
          <h2>Our History</h2>
        </div>
        <div class="timeline-event">
          <div class="timeline-event-icon">
            <i class="bx bx-coin-stack"></i>
          </div>
          <div class="timeline-event-date bg1">
            <span>JAN 2007</span>
          </div>
          <div class="timeline-event-content">
            <h3>Terracore is Born</h3>
            <p>
              Terracore has a history that dates back to 2009 when bitcoin was
              created, We laid the groundwork for the world’s first bitcoin
              trading service. Since then, we have grown from strength to
              strength, gaining the trust of traders around the world.
            </p>
          </div>
        </div>
        <div class="timeline-event">
          <div class="timeline-event-icon">
            <i class="bx bx-sun"></i>
          </div>
          <div class="timeline-event-date bg2">
            <span>MAY 2009</span>
          </div>
          <div class="timeline-event-content">
            <h3>The Rise of Terracore</h3>
            <p>
              We have positioned ourself as an authority with our tools and
              platforms which have clear & simple instructions & intuitively
              easy to navigate. We enforce best-in-class security measures such
              as SSL encryption to protect your account & personal data which
              have since proved our credibility, honesty, transparency,
              alongside years of experience.
            </p>
          </div>
        </div>
        <div class="timeline-event">
          <div class="timeline-event-icon">
            <i class="bx bxl-apple"></i>
          </div>
          <div class="timeline-event-date bg3">
            <span>APR 2012</span>
          </div>
          <div class="timeline-event-content">
            <h3>The Terracore Mobile App</h3>
            <p>
              Online Forex trading had never been easier with our efficient
              platform. Crypto markets, resources & Terracore account are in the
              palm of your hand with the Terracore app: use it to help you
              understand the markets with trading tools and the latest news,
              updates, and financial analysis.
            </p>
          </div>
        </div>
        <div class="timeline-event">
          <div class="timeline-event-icon">
            <i class="bx bxl-bitcoin"></i>
          </div>
          <div class="timeline-event-date bg4">
            <span>FEB 2017</span>
          </div>
          <div class="timeline-event-content">
            <h3>Adding Cryptocurrencies</h3>
            <p>
              Be your own bank! The widespread adoption of Cryptocurrencies has
              given the rise to new opportunities. The Bitcoin Cap alone is
              worth over $750 Billion and is growing rapidly. More than 300
              million users from more the 200 Countries use 200 currencies on a
              daily basis & that number keeps increasing significantly.
            </p>
          </div>
        </div>
        <div class="timeline-event">
          <div class="timeline-event-icon">
            <i class="bx bxs-pie-chart-alt"></i>
          </div>
          <div class="timeline-event-date bg5">
            <span>MAR 2020</span>
          </div>
          <div class="timeline-event-content">
            <h3>Trading Platform</h3>
            <p>
              Terracore is regulated by several entities including the Malta
              Financial Services Authority (MFSA), the Labuan Financial Services
              Authority (Labuan FSA), the Vanuatu Financial Services Commission
              (VFSC), and the British Virgin Islands Financial Services
              Commission, amongst many other regulator worldwide.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>