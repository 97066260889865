<template>
  <div class="guides-area ptb-100">
    <div class="container">
      <div class="guides-content">
        <div class="number">1</div>
        <div class="row m-0">
          <div class="col-lg-3 col-md-12 p-0">
            <div class="box">
              <h3>Step 1</h3>
              <span>Create a Terracore Account</span>
            </div>
          </div>
          <div class="col-lg-9 col-md-12 p-0">
            <div class="content">
              <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
                consectetur adipiscing.
              </p> -->
              <img src="../../assets/img/create-account.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="guides-content">
        <div class="number">2</div>
        <div class="row m-0">
          <div class="col-lg-3 col-md-12 p-0">
            <div class="box">
              <h3>Step 2</h3>
              <span>Select a prefered deposit and Withdrawal method</span>
            </div>
          </div>
          <div class="col-lg-9 col-md-12 p-0">
            <div class="content">
              <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </p> -->
              <img src="../../assets/img/select-coin.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="guides-content">
        <div class="number">3</div>
        <div class="row m-0">
          <div class="col-lg-3 col-md-12 p-0">
            <div class="box">
              <h3>Step 3</h3>
              <span>Fund Account</span>
            </div>
          </div>
          <div class="col-lg-9 col-md-12 p-0">
            <div class="content">
              <!-- <p>
                <strong>Terracore</strong> accepts the following payment
                methods:
              </p>
              <ul>
                <li>
                  Credit Card: <strong>Visa</strong>,
                  <strong>MasterCard</strong>, <strong>Discover</strong>,
                  <strong>American Express</strong>, <strong>JCB</strong>,
                  <strong>Visa Electron</strong>. The total will be charged to
                  your card when the order is shipped.
                </li>
                <li>
                  <strong>Terracore</strong> features a Fast Checkout option,
                  allowing you to securely save your credit card details so that
                  you don't have to re-enter them for future purchases.
                </li>
                <li>
                  PayPal: Shop easily online without having to enter your credit
                  card details on the website.Your account will be charged once
                  the order is completed. To register for a PayPal account,
                  visit the website <a href="#" target="_blank">paypal.com.</a>
                </li>
              </ul> -->
              <img src="../../assets/img/select-payment.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="guides-content">
        <div class="number">4</div>
        <div class="row m-0">
          <div class="col-lg-3 col-md-12 p-0">
            <div class="box">
              <h3>Step 4</h3>
              <span>Trade Markets</span>
            </div>
          </div>
          <div class="col-lg-9 col-md-12 p-0">
            <div class="content">
              <!-- <p>
                Items returned within 14 days of their original shipment date in
                same as new condition will be eligible for a full refund or
                store credit. Refunds will be charged back form of payment used
                for purchase. Customer is responsible for shipping charges when
                making returns and shipping/handling fees of original purchase
                is non-refundable.
              </p> -->
              <img src="../../assets/img/payment-method.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="guides-content">
        <div class="number">5</div>
        <div class="row m-0">
          <div class="col-lg-3 col-md-12 p-0">
            <div class="box">
              <h3>Step 5</h3>
              <span>Start earning Profits</span>
            </div>
          </div>
          <div class="col-lg-9 col-md-12 p-0">
            <div class="content">
              <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco.
              </p> -->
              <img src="../../assets/img/confirm-order.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Guides",
};
</script>