<template>
    <div>
        <Navbar/>
        <MarketHealth />
        <DataTable />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MarketHealth from '../Listings/MarketHealth'
import DataTable from '../Listings/DataTable'
import Footer from '../Layouts/Footer'

export default {
    name: 'ListingsPage',
    components: {
        Navbar,
        MarketHealth,
        DataTable,
        Footer,
    }
}
</script>