<template>
    <div class="data-table-area pt-70 pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="dataTables_length">
                        <label>Show 
                            <select name="myTable_length" aria-controls="myTable">
                                <option value="15">15</option>
                                <option value="18">18</option>
                                <option value="20">20</option>
                            </select> 
                            entries
                        </label>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="dataTables_filter">
                        <label>Search:
                            <input 
                                type="text" 
                                placeholder="Search Coin" 
                                @keyup="searchCoin"
                                v-model="textSearch"
                            >
                        </label>
                    </div>
                </div>
            </div>
            
            <div class="table-responsive cryptocurrency-data-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th 
                                v-for="title in titles"
                                :key="title.id"
                            >
                                {{title}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="coin in filteredCoins"
                            :key="coin.id"
                        >
                            <td>
                                <router-link to="/prices-details" class="d-block">
                                    <div class="d-flex align-items-center">
                                        <img :src="coin.image" alt="image">
                                        <h3 class="mb-0">{{coin.name}}</h3>
                                    </div>
                                </router-link>
                            </td>
                            <td><span>{{coin.symbol}}</span>${{coin.current_price}}</td>
                            <td>
                                <span 
                                    class="trending up" :class="[
                                        coin.price_change_percentage_24h > 0
                                        ? 'text-success'
                                        : 'text-danger'
                                    ]"
                                >
                                    {{coin.price_change_percentage_24h}}%
                                </span>
                            </td>
                            <td>${{coin.market_cap_change_percentage_24h}}</td>
                            <td><a target="_blank" href="https://www.coinbase.com/accounts" class="link-btn">Trade</a></td>
                        </tr>
                    </tbody>
                </table>
                
                <!-- <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="dataTables_info">
                            Showing {{ coins.startIndex }} to {{ coins.endIndex }} entries
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="pagination-area">
                            <div class="nav-links">
                                <a href="/prices" class="next page-numbers" title="Prev Page">
                                    <i class='bx bx-chevrons-left'></i>
                                </a>
                                <a href="/prices" class="page-numbers current">1</a>
                                <a href="/prices" class="page-numbers">2</a>
                                <a href="/prices" class="next page-numbers" title="Next Page">
                                    <i class='bx bx-chevrons-right'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataTable',
    data (){
        return {
            coins: [],
            filteredCoins: [],
            titles: ["Currency", "Price", "24h Change", "Market Cap", "Action"],
            textSearch: "",
        }
    },
    async mounted(){
        const res = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false')
        const data = await res.json()
        // console.log(data);
        this.coins = data;
        this.filteredCoins = data
    },
    methods: {
        searchCoin(){
            this.filteredCoins = this.coins.filter((coin) =>
                coin.name.toLowerCase().includes(this.textSearch.toLowerCase()) ||
                coin.symbol.toLowerCase().includes(this.textSearch.toLowerCase())
            );
        }
    }
}
</script>