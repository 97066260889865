<template>
  <div class="wallet-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="wallet-content">
            <h2>Markets to trade on Terracore</h2>
            <p>
              Learn about the markets that you can trade online with Terracore,
              including forex, synthetic indices, stocks & indices,
              cryptocurrencies, basket indices, and commodities.
            </p>
            <p>
              CFD trading allows you to trade on the price movement of an asset
              without buying or owning the underlying asset.Options trading
              allows for fixed payouts from correctly predicting market
              movements, without needing to buy an underlying asset.Multipliers
              allow you to trade on leverage while limiting downside risk to
              your investment. You can maximise your potential profit by several
              multiples of any market movement without risking more than your
              initial investment.
            </p>
            <a
              href="https://Terracore-inv.com/app/en/register.php"
              target=""
              class="default-btn"
              ><i class="bx bxs-user"></i> Create Account
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="wallet-image text-center">
            <img src="../../assets/img/wallet.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YourOwnBank",
};
</script>