<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>How To Sell Bitcoin</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img5.png" alt="image">
                        <h3>Got Your Bitcoin Wallet Ready?</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img4.png" alt="image">
                        <h3>Set Up Your Account</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img6.png" alt="image">
                        <h3>Sell Bitcoin</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SellBitcoin'
}
</script>